<template lang='pug'>
v-app
  v-app-bar(
    app
    dark
    flat
  )
    | LiftNerd
  v-main
    Uid
      router-view
</template>

<script>
import Uid from '@thomasphan/vue-components/src/components/Uid.vue';

export default
{
  name: 'App',
  components:
  {
    Uid
  },
};
</script>